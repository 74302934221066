import { AutoComplete, ISelectType, useFetch } from "@4uhub/lib4uhub";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { evaluationFormList } from "../../../services/evaluationForm.service";

const EvaluationFormSelector = () => {
  const { t } = useTranslation();

  const [options, setOptions] = useState<ISelectType[]>([]);

  const { sendRequest } = useFetch(evaluationFormList);

  const fetchEvaluationForm = useCallback(async () => {
    const { data, success } = await sendRequest({ FormTypeCodes: ["2"] });
    if (data && success) {
      setOptions(data.map((d) => ({ id: d.id, name: d.title })));
    }
  }, [sendRequest]);

  useEffect(() => {
    fetchEvaluationForm();
  }, [fetchEvaluationForm]);

  return (
    <AutoComplete
      name="evaluationForm"
      label={t("components.boards.form.evaluation_form")}
      getOptionLabel={(option) => option.name}
      size="small"
      fullWidth
      options={options}
    />
  );
};

export default EvaluationFormSelector;
