import { Button, Form, FormCheckBox, FormInput } from "@4uhub/lib4uhub";
import { zodResolver } from "@hookform/resolvers/zod";
import { Popover, PopoverProps, Stack, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import { IGetBoard } from "../../../models/Board";
import { TCreateBoardType, createBoardSchema } from "./AddBoardSchema";
import ParentBoardSelector from "./ParentBoardSelector";
import EvaluationFormSelector from "./EvaluationFormSelector";

interface IBoardPopOver {
  onSubmit: (data: TCreateBoardType) => void;
  loading?: boolean;
  open: boolean;
  anchorEl: Element | null;
  id?: string;
  onClose: (event: React.MouseEvent<HTMLButtonElement>) => void;
  popoverProps?: Partial<PopoverProps>;
  data?: IGetBoard;
  isView?: boolean;
  isGeneric?: boolean;
}

const BoardPopOver: React.FC<IBoardPopOver> = ({
  onSubmit,
  loading,
  anchorEl,
  open,
  onClose,
  id,
  popoverProps,
  data,
  isView,
  isGeneric,
}) => {
  const methods = useForm<TCreateBoardType>({
    resolver: zodResolver(createBoardSchema),
    defaultValues: {
      isView: isView,
      isEdit: false,
    },
  });

  const { t } = useTranslation();

  const isEdit = !!data?.id;

  const ref = useRef<HTMLInputElement>();

  const focusInInput = useCallback(() => {
    if (ref.current) {
      ref.current.focus();
    }
  }, [ref]);

  useEffect(() => {
    if (isEdit) {
      methods.reset({ ...data, isEdit: true });
    }
  }, [isEdit, data, methods]);

  useEffect(() => {
    setTimeout(focusInInput, 100);
  }, [focusInInput]);

  const handleSubmit = () => {
    methods.handleSubmit((t) => {
      onSubmit(t);
      methods.reset();
    })();
  };

  return (
    <>
      <Popover
        id={id}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        {...popoverProps}
      >
        <Form {...methods}>
          <Stack
            sx={(t) => ({
              padding: t.spacing(1),
              gap: t.spacing(2),
            })}
          >
            <Typography
              style={{
                textAlign: "center",
              }}
            >
              {t(
                isEdit
                  ? "components.boards.form.edit"
                  : "components.boards.form.create"
              )}
            </Typography>

            <FormInput
              name="name"
              size="small"
              label={t("components.boards.form.title")}
              inputRef={ref}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  handleSubmit();
                  ev.preventDefault();
                }
              }}
            />

            {isGeneric && <FormCheckBox name="isView" label="Quadro pai" />}

            {open && <ParentBoardSelector disable={isEdit} />}
            <EvaluationFormSelector />
            <Button
              variant="contained"
              disabled={!methods.formState.isValid}
              loading={loading}
              onClick={handleSubmit}
            >
              {t(
                isEdit
                  ? "components.boards.form.edit"
                  : "components.boards.form.create"
              )}
            </Button>
          </Stack>
        </Form>
      </Popover>
    </>
  );
};

export default memo(BoardPopOver);
