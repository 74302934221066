import { ISelectType } from "@4uhub/lib4uhub";
import { GlobalAxios } from "../axios/axios-config";
import {
  IInternalTemplateFields,
  IInternalTemplates,
  INewTicketInternalTemplate,
  IUpdatePayload,
} from "../models/internal-template";

const TICKET_AUTO_INTERNAL_TEMPLATE =
  process.env.REACT_APP_AUTOMATIONS +
  "/api/v1/TicketAutomationInternalTemplate";

const TICKET_INTERNAL_TEMPLATE =
  process.env.REACT_APP_TICKETS + "/api/v1/TicketInternalTemplate";

export const listTemplatesInternalTemplates = (params?: any) => {
  return GlobalAxios.get<ISelectType[]>(
    TICKET_INTERNAL_TEMPLATE + "/TicketAutomationInternalTemplate/list",
    {
      params: params,
    }
  );
};

export const getTemplateInternalTemplatesById = (id: string) => {
  return GlobalAxios.get<IInternalTemplates>(
    TICKET_AUTO_INTERNAL_TEMPLATE + "/" + id
  );
};

export const getTicketInternalTemplateByTicketId = (ticketId: string) => {
  return GlobalAxios.get<IInternalTemplateFields>(
    TICKET_INTERNAL_TEMPLATE + "/list?TicketId=" + ticketId
  );
};
export const updateTicketTemplateInternalFieldById = ({
  item,
}: {
  item: IUpdatePayload;
}) => {
  return GlobalAxios.put(
    TICKET_INTERNAL_TEMPLATE + "/UpdateTicketInternalTemplateFields",
    item
  );
};

export const createTicketTemplateInternal = (
  item: INewTicketInternalTemplate
) => {
  return GlobalAxios.post(TICKET_INTERNAL_TEMPLATE, item);
};
