import { Box, Grid, Modal, Typography } from "@mui/material";
import { memo, useCallback, useEffect } from "react";
import CheckCircleOutlineRoundedIcon from "@mui/icons-material/CheckCircleOutlineRounded";
import ReportGmailerrorredIcon from "@mui/icons-material/ReportGmailerrorred";
import { keyframes, styled } from "@mui/system";
import useTicket from "../../../../hooks/useTask";
import { t } from "i18next";
import CircularProgress from "@mui/material/CircularProgress";

const FinishModal = () => {
  const { ticket, onOpenFinishModal } = useTicket();
  const translationPath = "components.finishModal.";
  const ticketNumber = `#${ticket?.ticketSequential}`;

  const StyledBox = styled(Box)(({ theme }) => ({
    position: "absolute",
    width: 500,
    height: 400,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
    borderRadius: 7,
    color:
      !ticket.successFinishModal && !ticket.errorFinishModal
        ? "#696969"
        : "#ffffff",
    backgroundColor: "#ffffff",
    boxShadow: "0 0 15px #ffffff, 0 0 30px #ffffff",
    flexDirection: "column",
  }));

  const ProgressLine = styled("div")(() => ({
    position: "absolute",
    left: 0,
    width: "100%",
    height: `100%`,
    background:
      ticket.successFinishModal && !ticket.errorFinishModal
        ? "linear-gradient(to right, #a8e063, #56ab2f)"
        : ticket.errorFinishModal
        ? "linear-gradient(to right, #ff4e50, #f9d423)"
        : "#ffffff",
    boxShadow:
      !ticket.successFinishModal && !ticket.errorFinishModal
        ? "0 0 15px #ffffff, 0 0 30px #ffffff"
        : ticket.successFinishModal
        ? "0 0 15px #a8e063, 0 0 30px #56ab2f"
        : "0 0 15px #ff4e50, 0 0 30px #f9d423",
    zIndex: 0,
    borderRadius: 7,
    color:
      !ticket.successFinishModal && !ticket.errorFinishModal
        ? "#696969"
        : "#ffffff",
  }));

  const growAndRotate = keyframes`
  0% {
    transform: scale(0.5) rotate(0deg);
    opacity: 0;
    color: #ffffff;
  }
  10% {
    transform: scale(0.9) rotate(36deg);
    opacity: 0.5;
    // color: #4a90e2;
  }
  20% {
    transform: scale(1.3) rotate(72deg);
    opacity: 0.8;
    // color: #4a90e2;
  }
  30% {
    transform: scale(1.5) rotate(108deg);
    opacity: 1;
    // color: #4a90e2;
  }
  40% {
    transform: scale(1.8) rotate(144deg);
    // color: #4a90e2;
  }
  50% {
    transform: scale(2.1) rotate(180deg);
    // color: #2575fc;
  }
  60% {
    transform: scale(2.2) rotate(216deg);
    // color: #2575fc;
  }
  70% {
    transform: scale(2.3) rotate(252deg);
    opacity: 1;
    // color: #43a047;
  }
  80% {
    transform: scale(2.5) rotate(288deg);
    opacity: 1;
    // color: #43a047;
  }
  90% {
    transform: scale(2.7) rotate(324deg);
    opacity: 1;
    // color: #388e3c;
  }
  100% {
    transform: scale(2.5) rotate(360deg);
    opacity: 1;
    color: #ffffff;
  }
`;

  const SuccessAnimatedIcon = styled(CheckCircleOutlineRoundedIcon)(
    ({ theme }) => ({
      fontSize: "40px",
      animation: `${growAndRotate} 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards`,
    })
  );

  const ErrorAnimatedIcon = styled(ReportGmailerrorredIcon)(({ theme }) => ({
    fontSize: "40px",
    animation: `${growAndRotate} 0.5s cubic-bezier(0.42, 0, 0.58, 1) forwards`,
  }));

  const successHandle = useCallback(() => {
    setTimeout(() => {
      onOpenFinishModal(false);
    }, 1500);
  }, [onOpenFinishModal]);

  useEffect(() => {
    if (ticket.successFinishModal || ticket.errorFinishModal) {
      successHandle();
    }
  }, [successHandle, ticket.successFinishModal, ticket.errorFinishModal]);

  return (
    <Modal
      open={ticket.openFinishModal}
      onClose={(event, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      }}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <StyledBox>
        <ProgressLine />
        <Typography
          id="modal-title"
          variant="h6"
          fontSize={25}
          fontWeight="bold"
          textAlign="center"
          sx={{ zIndex: 10 }}
        >
          {t(translationPath + "ticketNumber")} {ticketNumber}
        </Typography>
        <Typography id="modal-description" sx={{ mt: 2, zIndex: 10 }}>
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Grid item sx={{ mt: 2 }}>
              {!ticket.successFinishModal && !ticket.errorFinishModal && (
                <div>
                  <CircularProgress sx={{ fontSize: "40px" }} />
                </div>
              )}
              {ticket.successFinishModal && (
                <div>
                  <SuccessAnimatedIcon />
                </div>
              )}
              {ticket.errorFinishModal && (
                <div>
                  <ErrorAnimatedIcon />
                </div>
              )}
            </Grid>
            <Grid item>
              <Typography
                variant="body1"
                textAlign="center"
                fontSize={20}
                fontWeight="bold"
                sx={{ mt: 4 }}
              >
                {!ticket.successFinishModal &&
                  !ticket.errorFinishModal &&
                  t(translationPath + "finalizingTicket")}
                {ticket.successFinishModal &&
                  !ticket.errorFinishModal &&
                  t(translationPath + "ticketCompletedSuccessfully")}
                {!ticket.successFinishModal &&
                  ticket.errorFinishModal &&
                  t(translationPath + "errorOnFinalizingTicket")}
              </Typography>
            </Grid>
          </Grid>
        </Typography>
      </StyledBox>
    </Modal>
  );
};

export default memo(FinishModal);
