import EditIcon from "@mui/icons-material/Edit";
import { useFetch, useRole } from "@4uhub/lib4uhub";
import { useTranslation } from "react-i18next";

import MenuItem from "../MenuItem";
import BoardPopOver from "../../AddBoard/BoardPopOver";
import { editBoard } from "../../../../services/board.service";
import { useWorkspaceContext } from "../../../../store/context/workspace-context";
import usePopover from "../../../../hooks/usePopover";
import { TCreateBoardType } from "../../AddBoard/AddBoardSchema";
import useBoardRole from "../../../../hooks/useBoardRole";

const EditBoard = () => {
  const { anchorEl, id, handleClick, handleClose, open } =
    usePopover("edit-board-popover");

  const { sendRequest, loading } = useFetch(editBoard);

  const updatePermission = useRole(["Update"]);

  const permission = useBoardRole("2");

  const { t } = useTranslation();

  const { actualBoard, actualWorkspace } = useWorkspaceContext();

  const onSubmit = async (data: TCreateBoardType) => {
    if (!actualBoard || !actualWorkspace) return;

    const value = {
      id: actualBoard.id,
      board: {
        ...actualBoard,
        ...data,
        enable: true,
        ticketWorkspaceId: actualWorkspace.id,
        evaluationFormId: data.evaluationForm?.id,
      },
    };

    const { success } = await sendRequest(value);

    if (success) {
      handleClose();
    }
  };

  return (
    <>
      {(updatePermission || permission) && (
        <MenuItem
          onClick={handleClick}
          title={t("components.boards.settingsMenu.editBoard")}
          icon={<EditIcon fontSize="small" color="primary" />}
        />
      )}

      {open && actualBoard && (
        <BoardPopOver
          isView={actualBoard.isView}
          data={actualBoard}
          popoverProps={{
            anchorOrigin: {
              vertical: "top",
              horizontal: "left",
            },
            transformOrigin: {
              vertical: "top",
              horizontal: "right",
            },
          }}
          anchorEl={anchorEl}
          onClose={handleClose}
          onSubmit={onSubmit}
          open={open}
          id={id}
          loading={loading}
        />
      )}
    </>
  );
};

export default EditBoard;
