import { Loading, useFetch } from "@4uhub/lib4uhub";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { Box, Card, IconButton, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ISalesContract } from "../../../../models/sales-contract";
import { ITicketRelationship } from "../../../../models/ticket";
import { getDomain } from "../../../../services/domain.service";
import { getEIFromUrl } from "../../../../services/establishment.service";
import { getTicketRelationshipSalesContractByTicketId } from "../../../../services/ticketRelationship";

const SalesContractInfoModal = ({
  id,
  ticketRelationships,
}: {
  id: string;
  ticketRelationships: ITicketRelationship[];
}) => {
  const { t } = useTranslation();

  const isLocalHost = window.location.hostname === "localhost";

  const [contracts, setContracts] = useState<ISalesContract[]>([]);

  const eI = getEIFromUrl();

  const { sendRequest, loading } = useFetch(
    getTicketRelationshipSalesContractByTicketId
  );

  const fetchData = useCallback(async () => {
    const { data, success } = await sendRequest(id);
    if (data && success) {
      setContracts(data);
    }
  }, [sendRequest, id]);

  const closeHandler = useCallback(
    (contractId: string) => {
      const relationship = ticketRelationships.find(
        (rel) => rel.salesContractBeneficiary?.salesContractId === contractId
      );

      const beneficiaryId =
        relationship && relationship.salesContractBeneficiary
          ? relationship.salesContractBeneficiary.id
          : null;

      const currentDomain = getDomain();

      const currentDomainArray = currentDomain.split(".");

      const softwareName = currentDomainArray[0].split("-")[0];

      const admDomain = `${softwareName}.${currentDomainArray
        .slice(1)
        .join(".")}`;

      const domainFormatted = isLocalHost
        ? "http://localhost:3006"
        : admDomain
        ? admDomain[admDomain.length - 1] === "/"
          ? admDomain.slice(0, -1)
          : admDomain
        : "";

      if (!beneficiaryId) return;

      window.open(
        `${
          isLocalHost
            ? domainFormatted
            : currentDomain.includes("dev")
            ? `https://dev.4uhub.com.br`
            : currentDomain.includes("hml")
            ? `https://admin.hml.4uhub.com.br`
            : `https://${domainFormatted}`
        }/e/${eI}/4uSalesContract/salesContract/SalesContractClubAbs/${contractId}/edit/${beneficiaryId}`,
        "blank"
      );
    },
    [eI, isLocalHost, ticketRelationships]
  );

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {contracts.length > 0 ? (
        contracts.map((contract, index) => (
          <Card
            key={contract.id || index}
            sx={{
              p: 0,
              m: 0,
              height: "fit-content",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                p: 1,
              }}
            >
              <Typography
                sx={{
                  p: 0,
                  m: 0,
                }}
              >{`${contract.code} - ${contract.contractTitle}`}</Typography>
              <IconButton
                onClick={() => closeHandler(contract.id)}
                aria-label="close contract"
                sx={{
                  p: 0,
                  m: 0,
                }}
              >
                <OpenInNewRoundedIcon />
              </IconButton>
            </Box>
          </Card>
        ))
      ) : (
        <Typography>{t("No contracts available")}</Typography>
      )}
    </>
  );
};

export default SalesContractInfoModal;
