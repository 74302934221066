import { BackButton } from "@4uhub/lib4uhub";
import { Box, Grid } from "@mui/material";
import { memo } from "react";
import { useNavigate } from "react-router-dom";

import useBoardRole from "../../../hooks/useBoardRole";
import useTicket from "../../../hooks/useTask";
import { IGetTicket } from "../../../models/ticket";
import TicketHeaderProvider from "../../../store/context/card-header-context";
import JoinAction from "../CardActions/JoinAction/JoinAction";
import LeaveAction from "../CardActions/LeaveAction";
import MoreActions from "../CardActions/MoreActions";
import MoveAction from "../CardActions/MoveAction/MoveAction";
import { CardReopen } from "../CardReopen/CardReopen";
import FinishAction from "../CardActions/FinishAction/FinishAction";
import FinishModal from "../CardActions/FinishModal/FinishModal";

interface ICardPageHeaderProps {
  title?: string;
  showBackButton?: boolean;
  isOpen?: boolean;
  onFinishClick?: (id: string) => void;
  onJoinClick?: (ticket: IGetTicket) => void;
  onArchiveClick?: (id: string) => void;
  onMove?: (id: string) => void;
}

const CardPageHeader: React.FC<ICardPageHeaderProps> = ({
  showBackButton = true,
  onArchiveClick,
  onFinishClick,
  onJoinClick,
  onMove,
}) => {
  const {
    isUserTicketMember,
    ticket: { id, status, openFinishModal },
  } = useTicket();

  const navigate = useNavigate();

  const permission = useBoardRole("2");

  const isDisabled = !isUserTicketMember || status?.code === "3";

  return (
    <TicketHeaderProvider
      onMove={onMove}
      onArchive={onArchiveClick}
      ticketId={id}
      onJoin={onJoinClick}
      onFinish={onFinishClick}
    >
      <Grid
        container
        direction={"row"}
        rowGap={1}
        marginY={1}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <Grid
          item
          container
          direction={"row"}
          columnGap={3}
          alignItems={"center"}
          xs={12}
          md={"auto"}
        >
          {showBackButton && (
            <Grid item xs={12} md={"auto"}>
              <Box onClick={() => navigate("..")}>
                <BackButton />
              </Box>
            </Grid>
          )}
        </Grid>
        <Grid item container direction="row" gap={1} xs="auto">
          {isUserTicketMember && status.code !== "3" && (
            <LeaveAction onMove={onMove ? () => onMove(id) : undefined} />
          )}

          {status.code === "3" && <CardReopen />}

          {permission && !isDisabled && <MoveAction />}

          {permission && status?.code !== "3" && <JoinAction />}

          {isUserTicketMember && status.code === "2" && <FinishAction />}

          {openFinishModal && <FinishModal />}

          {isUserTicketMember && <MoreActions />}
        </Grid>
      </Grid>
    </TicketHeaderProvider>
  );
};

export default memo(CardPageHeader);
