import { Loading, useFetch, UsePrivileges } from "@4uhub/lib4uhub";
import ChatIcon from "@mui/icons-material/Chat";
import HistoryIcon from "@mui/icons-material/History";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Grid, Typography } from "@mui/material";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import TableRowsIcon from "@mui/icons-material/TableRows";

import ListAltIcon from "@mui/icons-material/ListAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import CardHistoriesContainer from "../components/CardPage/CardHistories/CardHistoriesContainer";
import CardLogs from "../components/CardPage/CardHistories/CardLogs";
import CardContainer from "../components/CardPage/CardInfos";
import CardPageHeader from "../components/CardPage/CardPageHeader/CardPageHeader";
import { CardServices } from "../components/CardPage/CardServices/CardServices";
import Customer from "../components/CardPage/Customer";
import TabsComponent from "../components/CardPage/Tabs";
import Chat from "../components/Chat/Chat";
import CardChecklistsList from "../components/Checklist/CardChecklists/CardChecklistsList";
import { Events } from "../components/Events/Events";
import { SignalRContext } from "../components/SignalR/SignalRProvider";
import useEditTicket from "../hooks/useEditTicket";
import useSignalGroup from "../hooks/useSignalGroup";
import { ITransferTicketChannel } from "../models/channels";
import { IGetTicket } from "../models/ticket";
import { getTicketId } from "../services/ticket.service";
import TicketChannelProfilesService from "../services/ticketChannelProfiles.service";
import { useMembersContext } from "../store/context/members-context";
import TicketProvider from "../store/context/task-context";
import { CardInternalTemplate } from "../components/CardPage/CardInternalTemplate/CardInternalTemplate";

const usersService = new TicketChannelProfilesService();

const Card = () => {
  const { cardId, boardId } = useParams();

  const [card, setCard] = useState<IGetTicket>();

  const { t } = useTranslation();

  const navigate = useNavigate();

  const { sendRequest, loading } = useFetch(getTicketId);

  const { loadMembers } = useMembersContext();

  const props = useEditTicket(setCard);

  const { sendRequest: requestUsers } = useFetch(usersService.getItemById);

  const loadUsers = useCallback(async () => {
    if (boardId) {
      const { data, success } = await requestUsers(boardId);
      if (data && success) {
        loadMembers(data);
      }
    }
  }, [requestUsers, boardId, loadMembers]);

  useEffect(() => {
    loadUsers();
  }, [loadUsers]);

  const fetchCard = useCallback(async () => {
    if (!cardId) return;
    const { data, success } = await sendRequest(cardId);
    if (data && success) {
      setCard(data);
    }
  }, [cardId, sendRequest]);

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);

  const channelId = useMemo(() => `TicketChannel|${boardId}`, [boardId]);

  useSignalGroup(channelId);

  const returnPage = useCallback(() => {
    navigate("../..", {
      relative: "path",
    });
  }, [navigate]);

  SignalRContext.useSignalREffect(
    "TransferTicket", // Your Event Key
    (
      userId: string,
      { newTicketChannelStep, ticket }: ITransferTicketChannel
    ) => {
      if (card && card.id === ticket.id) {
        console.log("TransferTicket - card", ticket);
        setCard((c) => {
          if (c) {
            const newCard: IGetTicket = {
              ...c,
              ...ticket,
              ticketChannelStep: {
                ...c.ticketChannelStep,
                id: newTicketChannelStep.id,
                name: newTicketChannelStep.name,
                ticketChannelId: newTicketChannelStep.ticketChannel.id,
              },
            };
            return newCard;
          }
          return c;
        });
      }
    },
    [card]
  );

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      if (data.id === cardId) {
        console.log("TicketUpdated", data);
        setCard((o) => {
          if (o) {
            return { ...o, ...data };
          }
        });
      }
    },
    [cardId]
  );

  let tabs = [
    {
      icon: <ChatIcon fontSize="small" />,
      label: t("page.card.chat.title"),
      content: <Chat />,
    },
    {
      icon: <HistoryIcon fontSize="small" />,
      label: t("page.card.history"),
      content: <CardHistoriesContainer />,
    },
    {
      icon: <TrendingUpIcon fontSize="small" />,
      label: t("page.card.activity"),
      content: <CardChecklistsList />,
    },
    {
      icon: <ManageHistoryIcon fontSize="small" />,
      label: "Log",
      content: <CardLogs />,
    },
    {
      icon: <ListAltIcon fontSize="small" />,
      label: t("page.card.events"),
      content: <Events />,
    },
    {
      icon: <SupportAgentIcon fontSize="small" />,
      label: t("page.card.services"),
      content: <CardServices />,
    },
  ];

  const { privileges } = UsePrivileges();

  const isMaster = privileges.some(
    ({ domainName }) =>
      domainName === "internaltemplateticket" || domainName === "all"
  );

  if (isMaster) {
    tabs.push({
      icon: <TableRowsIcon fontSize="small" />,
      label: t("page.card.internal_template"),
      content: <CardInternalTemplate />,
    });
  }
  if (loading) {
    return <Loading sx={{ position: "relative" }} />;
  }

  if (!card) {
    return <Typography>No card founded</Typography>;
  }

  return (
    <TicketProvider loading={loading} ticket={card} {...props}>
      <Grid container px={2} py={2} columnSpacing={2} height={"100%"}>
        <Grid item container height={"100%"} rowGap={2} md={4} mb={2} xs={12}>
          <Customer />
          <CardContainer />
        </Grid>
        <Grid item container md={8} xs={12} height={"100%"}>
          <CardPageHeader
            title={card?.title}
            onArchiveClick={returnPage}
            onMove={returnPage}
          />
          <Grid item height={"calc(100% - 44.5px)"} xs={12}>
            <TabsComponent tabs={tabs} />
          </Grid>
        </Grid>
      </Grid>
    </TicketProvider>
  );
};

export default memo(Card);
