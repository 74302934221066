import { GlobalAxios } from "../axios/axios-config";
import { IEvaluationFormList } from "../models/evaluation-form";

const EVALUATION_FORM_ROUTE =
  process.env.REACT_APP_MAIN + "/api/v1/EvaluationForm/";

export const evaluationFormList = (params?: any) => {
  return GlobalAxios.get<IEvaluationFormList[]>(
    EVALUATION_FORM_ROUTE + "list",
    {
      params: params,
      paramsSerializer: { indexes: null },
    }
  );
};
