import { Loading, useFetch, useUser } from "@4uhub/lib4uhub";
import ChatIcon from "@mui/icons-material/Chat";
import HistoryIcon from "@mui/icons-material/History";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Box, Grid } from "@mui/material";
import { motion } from "framer-motion";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

import useFlow from "../../hooks/useFlow";
import TicketProvider from "../../store/context/task-context";

import CardPageHeader from "../CardPage/CardPageHeader/CardPageHeader";
import Tabs from "../CardPage/Tabs";
import Chat from "../Chat/Chat";
import NoCardSelected from "./NoCardSelected";
import TicketDetail from "./TicketDetail";

import ListAltIcon from "@mui/icons-material/ListAlt";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import useEditTicket from "../../hooks/useEditTicket";
import { ICardMember, IUserMember } from "../../models/card-members";
import { IMemberChannels, ITransferTicketChannel } from "../../models/channels";
import { IGetTicket } from "../../models/ticket";
import { getTicketId } from "../../services/ticket.service";
import CardHistoriesContainer from "../CardPage/CardHistories/CardHistoriesContainer";
import CardLogs from "../CardPage/CardHistories/CardLogs";
import { CardServices } from "../CardPage/CardServices/CardServices";
import CardChecklistsList from "../Checklist/CardChecklists/CardChecklistsList";
import { Events } from "../Events/Events";
import { SignalRContext } from "../SignalR/SignalRProvider";

const CardArea = () => {
  const {
    user: { id: userId },
  } = useUser();
  const { selectedTicket, userTickets, setSelectedTicket, setUserTickets } =
    useFlow();

  const { t } = useTranslation();

  const [ticket, setTicket] = useState<IGetTicket>();

  const props = useEditTicket(setTicket);

  const { sendRequest, loading } = useFetch(getTicketId);

  let content = <NoCardSelected />;

  const isOpen = useMemo(() => ticket?.status.code === "1", [ticket]);

  // const showChat = useMemo(
  //   () => ticket && ticket.ticketOriginType.code !== "1",
  //   [ticket]
  // );

  const fetchCard = useCallback(async () => {
    if (!selectedTicket) {
      setTicket(undefined);
      return;
    }

    const { data, success } = await sendRequest(selectedTicket);

    if (data && success) {
      setTicket(data);
    }
  }, [selectedTicket, sendRequest]);

  const handleMove = () => {
    if (selectedTicket) {
      const fileteredTickets = userTickets.filter(
        ({ id }) => id !== selectedTicket
      );

      setUserTickets(fileteredTickets);
    }
    setSelectedTicket(undefined);
  };

  const handleFinish = () => {
    setSelectedTicket(undefined);
  };

  useEffect(() => {
    fetchCard();
  }, [fetchCard]);

  SignalRContext.useSignalREffect(
    "TransferTicket", // Your Event Key
    (
      userId: string,
      { newTicketChannelStep, ticket: signalRTicket }: ITransferTicketChannel
    ) => {
      console.log("TransferTicket - card");

      if (ticket && ticket.id === signalRTicket.id) {
        setTicket((c) => {
          if (c) {
            return {
              ...c,
              ...signalRTicket,
              ticketChannelStep: {
                ...c.ticketChannelStep,
                id: newTicketChannelStep.id,
                name: newTicketChannelStep.name,
                ticketChannelId: newTicketChannelStep.ticketChannel.id,
              },
            };
          }
          return c;
        });
      }
    },
    [ticket?.id]
  );

  SignalRContext.useSignalREffect(
    "TicketUpdated", // Your Event Key
    (userId: string, data) => {
      console.log("TicketUpdated", data);

      if (data.id === ticket?.id) {
        setTicket((o) => {
          if (o) {
            return { ...o, ...data };
          }
        });
      }
    },
    [ticket?.id]
  );

  SignalRContext.useSignalREffect(
    "RemoveTicketMember",
    (
      userId: string,
      {
        ticketId,
        ticketMember: {
          user: { id: removedUserId } = {} as IUserMember,
        } = {} as ICardMember,
      }: IMemberChannels = {} as IMemberChannels
    ) => {
      const isMe = userId === removedUserId;
      const isSelectedTicket = selectedTicket === ticketId;

      if (isSelectedTicket && isMe) {
        setSelectedTicket(undefined);

        const fileteredTickets = userTickets.filter(
          ({ id }) => id !== ticketId
        );

        setUserTickets(fileteredTickets);
      }
    },
    [selectedTicket, userId, userTickets, setUserTickets]
  );

  if (ticket) {
    content = (
      <TicketProvider ticket={ticket} {...props}>
        <CardPageHeader
          isOpen={isOpen}
          title={ticket.title}
          showBackButton={false}
          onMove={handleMove}
          onFinishClick={handleFinish}
        />
        <Box width={"100%"} height={"calc(100% - 50px)"}>
          <Tabs
            tabs={[
              {
                icon: <ChatIcon fontSize="small" />,
                label: "Chat",
                content: <Chat />,
              },
              {
                icon: <TrendingUpIcon fontSize="small" />,
                label: t("page.card.activity"),

                content: <CardChecklistsList />,
              },
              {
                icon: <HistoryIcon fontSize="small" />,
                label: t("page.card.history"),
                content: <CardHistoriesContainer />,
              },
              {
                icon: <TextSnippetIcon fontSize="small" />,
                label: t("page.card.detail"),
                content: <TicketDetail />,
              },
              {
                icon: <ManageHistoryIcon fontSize="small" />,
                label: "Log",
                content: <CardLogs />,
              },
              {
                icon: <ListAltIcon fontSize="small" />,
                label: t("page.card.events"),
                content: <Events />,
              },
              {
                icon: <SupportAgentIcon fontSize="small" />,
                label: t("page.card.services"),
                content: <CardServices />,
              },
            ]}
          />
        </Box>
      </TicketProvider>
    );
  }

  if (loading) {
    content = (
      <Box
        height={"100%"}
        width={"100%"}
        alignItems={"center"}
        justifyContent={"center"}
        display={"flex"}
        flexDirection={"column"}
      >
        <Loading size={60} sx={{ position: "relative" }} />
      </Box>
    );
  }

  return (
    <Grid item container md={8} xs={12} height={"100%"}>
      <motion.div
        style={{
          height: "100%",
          overflow: "auto",
          width: "100%",
        }}
        key={ticket?.id}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5, ease: [0.6, -0.05, 0.01, 0.99] }}
      >
        {content}
      </motion.div>
    </Grid>
  );
};

export default CardArea;
