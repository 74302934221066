import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useCallback } from "react";
import { useFetch } from "@4uhub/lib4uhub";

import BoardPopOver from "./BoardPopOver";
import usePopover from "../../../hooks/usePopover";
import { TCreateBoardType } from "./AddBoardSchema";
import { createBoard } from "../../../services/board.service";
import { useWorkspaceContext } from "../../../store/context/workspace-context";

interface IBoardAddProps {
  isView: boolean;
}

const BoardAdd: React.FC<IBoardAddProps> = ({ isView }) => {
  const { loading, sendRequest } = useFetch(createBoard);

  const { actualWorkspace } = useWorkspaceContext();

  const { anchorEl, id, handleClick, handleClose, open } =
    usePopover("board-add-popover");

  const handleSubmit = useCallback(
    async (formData: TCreateBoardType) => {
      if (!actualWorkspace) return;

      const { success, data } = await sendRequest({
        name: formData.name,
        enable: true,
        evaluationFormId: formData.evaluationForm?.id,
        ticketWorkspaceId: actualWorkspace?.id,
        isView: formData.isView,
        parentId: formData.parent?.id,
      });

      if (success && data) {
        handleClose();
      }
    },
    [actualWorkspace, handleClose, sendRequest]
  );

  return (
    <>
      <IconButton
        aria-label="add"
        aria-describedby={id}
        color="primary"
        onClick={handleClick}
      >
        <AddIcon fontSize="small" color="primary" />
      </IconButton>

      <BoardPopOver
        anchorEl={anchorEl}
        onClose={handleClose}
        onSubmit={handleSubmit}
        open={open}
        isView={isView}
        loading={loading}
        id={id}
      />
    </>
  );
};

export default BoardAdd;
