import { Loading, useFetch } from "@4uhub/lib4uhub";
import { Stack } from "@mui/material";
import { AnimatePresence } from "framer-motion";
import { memo, useCallback, useEffect, useState } from "react";
import Dropzone, { DropEvent } from "react-dropzone";

import moment from "moment";
import useMessageInput from "../../hooks/useMessageInput";
import useTicket from "../../hooks/useTask";
import { uploadFile } from "../../services/fileManager.service";
import { finishTicket } from "../../services/ticket.service";
import { useTicketHeader } from "../../store/context/card-header-context";
import { useChat } from "../../store/context/chat-context";
import MessageInputProvider from "../../store/context/messageInput-context";
import ChatHeader from "./ChatHeader";
import ChatMessageInput from "./ChatMessageInput";
import { DragScreen } from "./DragScreen";
import { FinishTicketConfirmModal } from "./FinishTicketConfirmModal/FinishTicketConfirmModal";
import MessageBox from "./MessageBox";
import { NoMessages } from "./NoMessages/NoMessages";
import { SelectAutomationRule } from "./NoMessages/SelectAutomationRule/SelectAutomationRule";
import { RestartMessagePopUp } from "./RestartMessagePopUp/RestartMessagePopUp";

export const ChatBody = memo(() => {
  const { sendMessage } = useChat();

  return (
    <MessageInputProvider onMessageSend={sendMessage}>
      <DragContainer />
    </MessageInputProvider>
  );
});

const DragContainer = memo(() => {
  const { setFiles, setLoading } = useMessageInput();

  const { messages, loading } = useChat();

  const { ticket, isUserTicketMember, onEditStatus, onEditList } = useTicket();

  const { whatsappNumber } = ticket;

  const { sendRequest } = useFetch(uploadFile);

  const { onFinish } = useTicketHeader();

  const { sendRequest: onFinishTicket, loading: floading } =
    useFetch(finishTicket);

  const [open, setOpen] = useState(false);
  const onCloseModalHandler = () => setOpen(false);
  const onOpenModal = () => setOpen(true);

  const [expiredTime, setExpiredTime] = useState(false);

  const [restartChat, setRestartChat] = useState(false);

  const { status } = ticket;

  const showRestartMessage =
    expiredTime && status.code !== "3" && isUserTicketMember;

  const handleDrag: <T extends File>(
    files: T[],
    event: DropEvent
  ) => Promise<void> = useCallback(
    async (files) => {
      await Promise.all(
        files.map(async (f) => {
          const { data, success } = await sendRequest({
            File: f,
            type: "Private",
            ParentName: "TicketDialog",
            MediaTypeIdentifier: "SiteAppMediaType",
            MediaTypecode: "2",
          });
          if (data && success) {
            setFiles((files) => [...files, { file: f, id: data.id }]);
          }
        })
      );
      setLoading(false);
    },
    [sendRequest, setLoading, setFiles]
  );

  const onDrop = useCallback(() => setLoading(true), [setLoading]);

  const restartChatHandler = useCallback(() => {
    setRestartChat(true);
  }, []);

  const backToChatHandler = useCallback(() => {
    setRestartChat(false);
  }, []);

  const removeExpiredTime = useCallback(() => {
    setExpiredTime(false);
  }, []);

  const ticketFinishHandler = useCallback(async () => {
    if (!ticket) return;
    const { data, success } = await onFinishTicket({
      id: ticket.id,
    });
    if (data && success) {
      onFinish && onFinish(ticket.id);
      onEditStatus && onEditStatus(data.status);
      onEditList && onEditList(data.ticketChannelStep);
      onCloseModalHandler();
    }
  }, [ticket, onFinishTicket, onEditStatus, onEditList, onFinish]);

  useEffect(() => {
    const lastMessage = messages[messages.length - 1];

    if (!lastMessage) return;

    const oneDayDiff = moment().diff(lastMessage.dateSend, "days");

    if (oneDayDiff >= 1) {
      setExpiredTime(true);
      return;
    }

    if (lastMessage.deliveryReasonCode === "131047") {
      setExpiredTime(true);
    }

    return () => {
      setExpiredTime(false);
    };
  }, [messages]);

  if (loading || floading) {
    return (
      <Stack
        sx={{
          width: "100%",
          height: "100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          position: "relative",
        }}
      >
        <Loading />
      </Stack>
    );
  }

  if (messages.length === 0) {
    return <NoMessages />;
  }

  if (restartChat) {
    return (
      <SelectAutomationRule
        whatsappNumber={
          whatsappNumber
            ? whatsappNumber
            : ticket.ticketOrigins.length > 0
            ? ticket.ticketOrigins[0].phoneNumber
            : ""
        }
        onBack={backToChatHandler}
        onSucces={removeExpiredTime}
        restartChat
      />
    );
  }

  return (
    <>
      <FinishTicketConfirmModal
        onCloseModalHandler={onCloseModalHandler}
        open={open}
        onFinishHandler={ticketFinishHandler}
      />
      <Dropzone onDropAccepted={handleDrag} onDrop={onDrop} noClick>
        {({ getRootProps, isDragActive }) => (
          <Stack
            position={"relative"}
            width={"100%"}
            height={"100%"}
            {...getRootProps({ className: "dropzone" })}
          >
            <AnimatePresence>
              {isDragActive ? (
                <DragScreen />
              ) : (
                <>
                  {showRestartMessage && (
                    <RestartMessagePopUp
                      onClickRestart={restartChatHandler}
                      onClickFinish={onOpenModal}
                    />
                  )}
                  <ChatHeader />
                  <MessageBox />
                  <ChatMessageInput />
                </>
              )}
            </AnimatePresence>
          </Stack>
        )}
      </Dropzone>
    </>
  );
});
