import { Stack, Tooltip, Typography } from "@mui/material";
import { memo } from "react";
import { Variants, motion } from "framer-motion";

import { ITag } from "../../../models/tags";
import { convertRgbToHex } from "../../../utils/ConvertRgbToHex";

const variants: Variants = {
  closed: { height: 10, width: 15 },
  open: { height: "fit-content", width: "fit-content" },
};

const Tag: React.FC<{
  data: ITag;
  padding?: number;
  fontSize?: number;
  showTagName?: boolean;
  fullWidth?: boolean;
}> = ({ data, padding, fontSize = 14, showTagName = true, fullWidth }) => {
  return (
    <Tooltip title={!showTagName ? data.name : ""}>
      <Stack
        padding={padding || padding === 0 ? padding : 0.7}
        paddingX={1.4}
        sx={{
          backgroundColor: data.color.includes("rgb")
            ? convertRgbToHex(data.color)
            : data.color,
          borderRadius: 1,
          width: "100%",
        }}
      >
        <motion.div
          variants={!fullWidth ? variants : undefined}
          style={fullWidth ? { width: "100%" } : undefined}
          animate={showTagName ? "open" : "closed"}
          transition={{ duration: 0.2, type: "tween", ease: "linear" }}
        >
          <Typography
            variant="body1"
            color={(t) =>
              showTagName && (data.color.includes("rgb") || data.color.includes("#"))
                ? t.palette.getContrastText(
                    data.color.includes("rgb")
                      ? convertRgbToHex(data.color)
                      : data.color
                  )
                : data.color
            }
            sx={{
              opacity: 1,
              fontSize: fontSize,
              minHeight: 10,
              fontWeight: 500,
              minWidth: 15,
              width: "100%",
            }}
            noWrap
          >
            {showTagName ? data.name : ""}
          </Typography>
        </motion.div>
      </Stack>
    </Tooltip>
  );
};

export default memo(Tag);
